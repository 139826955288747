import axios, {AxiosPromise} from "axios";
import {AdditionalPayment, AdditionalPaymentGroup, AdditionalPaymentTM} from "../model/AdditionalPayment";
import {EditRow} from "../model/EditRow";
import { PaymentsReq } from "../model/PaymentsReq";
import axiosInstance from "../hooks/useUserInfo"
export const getPaymentSummary = (year: number, month: number, type: string): AxiosPromise => {
    type = type ? type+'-summary/':'';
    return axiosInstance.get(`/api/v1/summary/${type}${year}/${month}`,{timeout: 300000});
}

export const getEarlyPaymentSummary = (year: number, month: number): AxiosPromise => {

    return axiosInstance.get(`/api/v1/summary/payments/ep/${year}/${month}?checkEarlyPayment=true`);
}

export const getEarlyPaymentProgress = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/early-payments/progress/${year}/${month}`);
}

export const getPaymentsMonitor = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v2/payments/prowallet/batch/${year}/${month}`);
}

export const loadAndGetPaymentSummary = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/summary/load/${year}/${month}`);
}

export const addAdditionalPayment = (year: number, month: number, kpeId: string, ap: AdditionalPayment): AxiosPromise => {
    return axiosInstance.post(`/api/v1/summary/payment/${year}/${month}/${kpeId}`, ap);
}

export const addAdditionalPaymentGroup = (year: number, month: number, groupName: string, ap: AdditionalPaymentGroup): AxiosPromise => {
    // @ts-ignore
    ap.groupId = ap.id
    return axiosInstance.post(`/api/v1/summary/group-payment`, ap);
}

export const addAdditionalPaymentTM = (year: number, month: number, groupName: string, ap: AdditionalPaymentTM): AxiosPromise => {
    // @ts-ignore
    ap.talentManagerId = ap.id;
    ap.id = '';
    // @ts-ignore
    return axiosInstance.post(`/api/v1/summary/tm-payment/${year}/${month}/${ap.talentManagerId}`, ap);
}

export const deleteAdditionalPayment = (id: string): AxiosPromise => {
    return axiosInstance.delete(`/api/v1/summary/payment/${id}`);
}

export const deleteAdditionalPaymentTM = (id: string): AxiosPromise => {
    return axiosInstance.delete(`/api/v1/summary/tm-payment/${id}`);
}

export const deleteAdditionalPaymentGroup = (id: string): AxiosPromise => {
    return axiosInstance.delete(`/api/v1/summary/group-payment/${id}`);
}


export const ingestAllYouTubeData = (year: number, month: number): AxiosPromise => {
    return axiosInstance.post(`/api/v1/yt/ingest/all/${year}/${month}`);
}

export const getErredPayments = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/summary/payments/error/${year}/${month}`);
}

export const getProgress = (year: number, month: number): AxiosPromise => {
    return axiosInstance.get(`/api/v1/summary/progress/${year}/${month}`)
}

export const updateRow = (row: EditRow): AxiosPromise => {
    return axiosInstance.post(`/api/v2/gam/calculations/overwrite`, row);
}
export const downloadCSV = (year: number, month: number, type: string, idsToExportString?: string): AxiosPromise => {
    return axiosInstance.get(`/api/v1/summary/csv-export/${type}-summary/${year}/${month}`,
    { responseType: 'blob', params: {ids:idsToExportString}})
}

export const downloadCSVEP = (year: number, month: number, idsToExportString?: string): AxiosPromise => {
    return axiosInstance.get(`/api/v1/summary/csv-export/ep-summary/${year}/${month}`,
    { responseType: 'blob', params: {ids:idsToExportString}})
}

export const submitPayments = (payments: PaymentsReq): AxiosPromise => {
    return axiosInstance.post(`/api/v2/payments/prowallet/creators`, payments);
}
export const submitGroupsPayments = (payments: PaymentsReq): AxiosPromise => {
    return axiosInstance.post(`/api/v2/payments/prowallet/groups`, payments);
}

export const submitTMPayments = (payments: PaymentsReq): AxiosPromise => {
    return axiosInstance.post(`/api/v2/payments/prowallet/talent-managers`, payments);
}